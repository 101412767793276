import { Directive, ElementRef, Input, OnInit, Optional, Renderer2 } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpErrorHandler } from '../../http/http-error-handler.service';
import { Store } from '@ngrx/store';
import { SessionTokenState } from '../../app-state/states/app.states';
import { getSessionToken } from '../../app-state/selectors/app.state.selectors';

@Directive({
    selector: '[appAuth]',
})
export class AuthDirective implements OnInit {
    @Input() hideIfNotAuthenticated: boolean;
    @Input() disableIfNotAuthenticated = false;

    constructor(
        private el?: ElementRef,
        private auth?: AuthService,
        private renderer?: Renderer2,
        private store?: Store<SessionTokenState>,
    ) {}

    ngOnInit(): void {
        this.store.select(getSessionToken).subscribe((sessionTokenHolder) => {
            if (sessionTokenHolder) {
                if (this.hideIfNotAuthenticated) {
                    this.el.nativeElement.style.display = 'block';
                } else {
                    this.el.nativeElement.style.display = 'none';
                }
            } else {
                if (this.hideIfNotAuthenticated) {
                    this.el.nativeElement.style.display = 'none';
                } else {
                    this.el.nativeElement.style.display = 'block';
                }
            }
        });
    }
}
