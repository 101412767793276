import { Injectable } from '@angular/core';
import { ConfigService } from '@ngx-config/core';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentProviderService {
    constructor(private readonly config: ConfigService) {}

    private screenSize: string;
    public get getScreenSize(): string {
        return this.screenSize;
    }

    private browser: string;
    public get getBrowser(): string {
        return this.browser;
    }

    private browserVersion: string;
    public get getBrowserVersion(): string {
        return this.browserVersion;
    }

    private browserMajorVersion: number;
    public get getBrowserMajorVersion(): number {
        return this.browserMajorVersion;
    }

    private mobile: boolean;
    public get isMobile(): boolean {
        return this.mobile;
    }

    private cookieEnabled: boolean;
    public get isCookieEnabled(): boolean {
        return this.cookieEnabled;
    }

    private os: string;
    public get getOS(): string {
        return this.os;
    }

    private osVersion: any;
    public get getOSVersion(): any {
        return this.osVersion;
    }

    private buildNumber: string;
    public get getBuildNumber(): string {
        return this.buildNumber;
    }

    private deviceModel: string;
    public get getDeviceModel(): string {
        return this.deviceModel;
    }

    private deviceId: string;
    public get getDeviceId(): string {
        return this.deviceId;
    }

    public fillEnvSettings(): void {
        const unknown = '-';

        // screen
        if (screen.width) {
            const width = screen.width ? screen.width : '';
            const height = screen.height ? screen.height : '';
            this.screenSize += '' + width + ' x ' + height;
        }

        // browser
        const nVer = navigator.appVersion;
        const nAgt = navigator.userAgent;
        this.browser = navigator.appName;
        this.browserVersion = '' + parseFloat(navigator.appVersion);
        this.browserMajorVersion = parseInt(navigator.appVersion, 10);
        let nameOffset;
        let verOffset;
        let ix;

        // Opera

        if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
            this.browser = 'Opera';
            this.browserVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                this.browserVersion = nAgt.substring(verOffset + 8);
            }
        }
        // Opera Next
        if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
            this.browser = 'Opera';
            this.browserVersion = nAgt.substring(verOffset + 4);
        }
        // Edge
        else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
            this.browser = 'Microsoft Edge';
            this.browserVersion = nAgt.substring(verOffset + 5);
        }
        // MSIE
        else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
            this.browser = 'Microsoft Internet Explorer';
            this.browserVersion = nAgt.substring(verOffset + 5);
        }
        // Chrome
        else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
            this.browser = 'Chrome';
            this.browserVersion = nAgt.substring(verOffset + 7);
        }
        // Safari
        else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
            this.browser = 'Safari';
            this.browserVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                this.browserVersion = nAgt.substring(verOffset + 8);
            }
        }
        // Firefox
        else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
            this.browser = 'Firefox';
            this.browserVersion = nAgt.substring(verOffset + 8);
        }
        // MSIE 11+
        else if (nAgt.indexOf('Trident/') !== -1) {
            this.browser = 'Microsoft Internet Explorer';
            this.browserVersion = nAgt.substring(nAgt.indexOf('rv:') + 3);
        }
        // Other browsers
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
            this.browser = nAgt.substring(nameOffset, verOffset);
            this.browserVersion = nAgt.substring(verOffset + 1);
            if (this.browser.toLowerCase() === this.browser.toUpperCase()) {
                this.browser = navigator.appName;
            }
        }
        // trim the this.browserVersion string
        if ((ix = this.browserVersion.indexOf(';')) !== -1) {
            this.browserVersion = this.browserVersion.substring(0, ix);
        }
        if ((ix = this.browserVersion.indexOf(' ')) !== -1) {
            this.browserVersion = this.browserVersion.substring(0, ix);
        }
        if ((ix = this.browserVersion.indexOf(')')) !== -1) {
            this.browserVersion = this.browserVersion.substring(0, ix);
        }

        this.browserMajorVersion = parseInt('' + this.browserVersion, 10);
        if (isNaN(this.browserMajorVersion)) {
            this.browserVersion = '' + parseFloat(navigator.appVersion);
            this.browserMajorVersion = parseInt(navigator.appVersion, 10);
        }

        // mobile browserVersion
        this.mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

        // cookie
        this.cookieEnabled = navigator.cookieEnabled;

        if (typeof navigator.cookieEnabled === 'undefined' && !this.cookieEnabled) {
            document.cookie = 'testcookie';
            this.cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
        }

        // system
        this.os = unknown;
        const clientStrings = [
            { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
            { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
            { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
            { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
            { s: 'Windows Vista', r: /Windows NT 6.0/ },
            { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
            { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
            { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
            { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
            { s: 'Windows 98', r: /(Windows 98|Win98)/ },
            { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
            { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
            { s: 'Windows CE', r: /Windows CE/ },
            { s: 'Windows 3.11', r: /Win16/ },
            { s: 'Android', r: /Android/ },
            { s: 'Open BSD', r: /OpenBSD/ },
            { s: 'Sun OS', r: /SunOS/ },
            { s: 'Chrome OS', r: /CrOS/ },
            { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
            { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
            { s: 'Mac OS X', r: /Mac OS X/ },
            { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
            { s: 'QNX', r: /QNX/ },
            { s: 'UNIX', r: /UNIX/ },
            { s: 'BeOS', r: /BeOS/ },
            { s: 'OS/2', r: /OS\/2/ },
            { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
        ];
        // tslint:disable-next-line:forin
        for (const id in clientStrings) {
            const cs = clientStrings[id];
            if (cs.r.test(nAgt)) {
                this.os = cs.s;
                break;
            }
        }

        if (/Windows/.test(this.os)) {
            this.osVersion = /Windows (.*)/.exec(this.os)[1];
            this.os = 'Windows';
        }

        switch (this.os) {
            case 'Mac OS X':
                this.osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
                break;

            case 'Android':
                this.osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
                break;

            case 'iOS':
                // @ts-ignore
                this.osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                // tslint:disable-next-line:no-bitwise
                this.osVersion = this.osVersion[1] + '.' + this.osVersion[2] + '.' + (this.osVersion[3] | 0);
                break;
        }

        /*// flash (you'll need to include swfobject)
        /!* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" *!/
        let flashVersion = 'no check';
        if (typeof swfobject !== 'undefined') {
            const fv = swfobject.getFlashPlayerVersion();
            if (fv.major > 0) {
                flashVersion = fv.major + '.' + fv.minor + ' r' + fv.release;
            } else {
                flashVersion = unknown;
            }
        }*/
        this.buildNumber = this.config.getSettings('build_number');

        this.deviceModel = navigator.userAgent.substring(
            navigator.userAgent.indexOf('(') + 1,
            navigator.userAgent.indexOf(')'),
        );

        this.deviceId = navigator.userAgent;
    }
}
