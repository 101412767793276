import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { RideHistoryService } from './ride-history.service';
import { Card } from '../domain/card';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../security/service/auth.service';

@Injectable({
    providedIn: 'root',
})
export class RideHistoryResolverService implements Resolve<Card[]> {
    constructor(
        private readonly rideHistoryService: RideHistoryService,
        private readonly router: Router,
        private readonly authService: AuthService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Card[] | Observable<Card[]> | Promise<Card[]> {
        const status = route.paramMap.get('status');
        return this.rideHistoryService.getCardList().pipe(
            take(1),
            mergeMap((cards) => {
                if (cards && cards.cards.length > 0) {
                    return of(cards.cards);
                } else {
                    console.log('No cards available at this time');
                    status
                        ? this.router.navigate(['/ride-history-without-cards', status])
                        : this.router.navigate(['/ride-history-without-cards']);
                    return null;
                }
            }),
            catchError((error) => {
                if (error.status === 401) {
                    return;
                }
                const message = `Retrieval error: ${error}`;
                console.error(message);
                this.authService.dataStream.next(1);
                return null;
            }),
        );
    }
}
