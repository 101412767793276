import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubSink } from '../localization/utils';
import { LocalizationService } from '../localization/service/localization.service';
import luhnCheck from 'fast-luhn';
import { sha256 } from 'js-sha256';
// @ts-ignore
import creditCardType from 'credit-card-type';
import { Router } from '@angular/router';

function luhnValidator(input: FormControl) {
  if (!input.value) {
    return null;
  }
  const isValid = luhnCheck(input.value);
  return isValid ? null : { luhnCheck: true };
}

declare var $: any;
const MIN_PAN_LENGTH_FOR_BRANDING = 4;
const MIN_PAN_LENGTH = 13;

@Component({
  selector: 'app-hash-pan',
  templateUrl: './hash-pan.component.html',
  styleUrls: ['./hash-pan.component.scss'],
})
export class HashPanComponent implements OnInit, OnDestroy {
  public creditCardForm: FormGroup;
  public readonly subSink: SubSink = new SubSink();
  public pan: string;
  public hashedPan: string;
  public cardBrand: string;
  public highlighted = false;
  public brands = ['unionpay', 'mastercard', 'visa', 'maestro', 'mir'];

  constructor(
      private readonly localizationService: LocalizationService,
      private readonly formBuilder: FormBuilder,
      private readonly router: Router,
      private renderer: Renderer2,
      @Inject(DOCUMENT) private document: Document
  ) {}

  onSubmit(): void {
    if (!this.creditCardForm.valid) { return; }
    const hashedPan = sha256(this.creditCardForm.controls['pan'].value);
    this.hashedPan = hashedPan.toUpperCase();

    this.copyHash();
  }

  onPaste(event) {
    setTimeout(() => { event.target.selectionStart = event.target.value.length; }, 0);
  }

  copyHash() {
    if (this.highlighted) { return; }
    this.highlighted = true;
    setTimeout(() => { this.highlighted = false; }, 2000);

    function iosCopyToClipboard(el) {
      const oldContentEditable = el.contentEditable;
      const oldReadOnly = el.readOnly;
      const range = document.createRange();

      el.contentEditable = true;
      el.readOnly = false;
      range.selectNodeContents(el);

      const s = window.getSelection();
      s.removeAllRanges();
      s.addRange(range);

      el.setSelectionRange(0, 999999);
      el.contentEditable = oldContentEditable;
      el.readOnly = oldReadOnly;

      document.execCommand('copy');
    }

    // @ts-ignore
    if (window.clipboardData) {
      // @ts-ignore
      window.clipboardData.setData('Text', this.hashedPan);
    } else if (window.navigator.clipboard) {
      window.navigator.clipboard
          .writeText(this.hashedPan)
          .catch(e => console.error(e));
    } else {
      setTimeout(() => {
        const panInput = document.getElementById('hash');
        iosCopyToClipboard(panInput);
      }, 0);
    }
  }

  onClose() {
    $('#hashPan').modal('hide');
    this.router.navigate(['/']).then();
  }

  onInput(e): void {
    if (!this.creditCardForm.controls['pan'].valid) {
      this.creditCardForm.controls['pan'].setErrors(null);
    }

    if (this.hashedPan) { this.hashedPan = ''; }
    this.pan = e.target.value;
    const [{type = ''} = {}] = creditCardType(this.pan);
    this.cardBrand = this.pan.length >= MIN_PAN_LENGTH_FOR_BRANDING ? type : '';
  }

  isDisabledSubmitBtn(): boolean {
    return !this.pan
        || (this.pan && this.pan.replace(/\s/g, '').length < MIN_PAN_LENGTH)
        || (this.creditCardForm.controls['pan'].value && !this.creditCardForm.controls['pan'].valid);
  }

  ngOnInit(): void {
    sessionStorage.removeItem('routeAfterLogin');
    this.creditCardForm = this.formBuilder.group({
      pan: ['', {
        validators: [Validators.required, Validators.minLength(MIN_PAN_LENGTH), luhnValidator],
        updateOn: 'submit'
      }]
    });

    this.renderer.addClass(this.document.body, 'bg-blur');
    $('#hashPan').modal();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}

