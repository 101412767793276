import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocalizationService } from '../../../localization/service/localization.service';
import { SubSink } from '../../../localization/utils';
import { ConfigService } from '@ngx-config/core';
declare var $: any;

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit, OnDestroy {
    supportMail: string;
    supportHref: string;
    currentLogoBlackURL: string;
    brandLogoCSSClass: string;
    protected readonly subSink: SubSink = new SubSink();

    constructor(private readonly localizationService: LocalizationService,
                private readonly config: ConfigService) {
        this.subSink.sink = this.localizationService.languageChanged.subscribe(() => {
            this.currentLogoBlackURL = this.localizationService.getMessage('app.currentLogoBlackURL');
            this.brandLogoCSSClass = this.localizationService.getMessage('app.brandLogoCSSClass');
        });
    }

    ngOnInit(): void {
        this.supportMail = this.config.getSettings('support');
        this.supportHref = `mailto:${this.supportMail}`;
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

    goToMosmetro() {
        window.location.href = 'https://www.mosmetro.ru/info/feedback/';
    }

    goToFAQ() {
        $('#eventsModal').modal('hide');
        setTimeout(() => $('#questionAnswerModal').modal('show'), 500);
    }

    showConfirmToDeleteUserProfileDialog() {
        $('#supportModal').modal('hide');
        $('#confirmDeleteUserDialog').modal('show');
    }
}
