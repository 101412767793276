<div id="userAgreementModal"
     class="modal fade userAgreementModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="modalCenterTitle"
     data-backdrop="static"
     aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body" *ngIf="isConfirmState;else deleteState">
                <div class="mt-4 confirm-dialog-title">
                    {{ 'auth.confirmPrivatePolicy.title' | translate }}
                </div>

                <div class="text-center mt-4">
                    {{ 'auth.confirmPrivatePolicy.question.part1' | translate }}
                    <a class="mug-link font-weight-bold" [href]="personalDataLink">
                        {{ 'auth.confirmPrivatePolicy.question.part2' | translate }}
                    </a>
                </div>

                <div class="p-1 p-md-4 d-flex justify-content-between">
                    <button type="button"
                            class="btn button-white"
                            data-toggle="modal"
                            data-dismiss="modal">
                        {{ 'auth.confirmPrivatePolicy.btn.cancel' | translate }}
                    </button>

                    <button type="button"
                            class="btn button-red"
                            (click)="onAgree()">
                        {{ 'auth.confirmPrivatePolicy.btn.agree' | translate }}
                    </button>
                </div>
            </div>

            <div *ngIf="isConfirmState"
                 class="modal-footer justify-content-center">
                <div class="text-center w-75 mb-3">
                    {{ 'auth.confirmPrivatePolicy.footer.info' | translate }}
                    <a [href]="personalDataLink">
                        {{ 'auth.confirmPrivatePolicy.footer.info-link' | translate }}
                    </a>
                </div>

                <div class="text-center w-75">
                    {{ 'auth.confirmPrivatePolicy.footer.delete' | translate }}
                    <a href="#" (click)="isConfirmState = false">
                        {{ 'auth.confirmPrivatePolicy.footer.delete-link' | translate }}
                    </a>
                </div>
            </div>

            <ng-template #deleteState>
                <div class="modal-body">
                    <div class="d-flex justify-content-center confirm-dialog-title">
                        {{ 'app.confirmDeleteUserTitle' | translate }}
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="flex-column pt-md-3 px-4 centered-content">
                            {{ 'auth.deleteProfile.title' | translate }}
                        </div>
                    </div>

                    <div class="mt-3 p-1 p-md-4 d-flex justify-content-between">
                        <button type="button"
                                class="btn button-white"
                                data-toggle="modal"
                                (click)="isConfirmState = true">
                            {{ 'auth.deleteProfile.btn.delete.no' | translate }}
                        </button>

                        <button type="button"
                                class="btn button-red"
                                data-toggle="modal"
                                data-dismiss="modal"
                                (click)="delete.emit()">
                            {{ 'auth.deleteProfile.btn.delete.yes' | translate }}
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
