<div class="modal fade"
     id="hashPan"
     tabindex="-1"
     role="dialog"
     aria-labelledby="modalCenterTitle"
     data-backdrop="static"
     aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="mt-4 confirm-dialog-title">
                    {{ 'hash-pan.title' | translate }}
                </div>

                <button type="button" class="close" (click)="onClose()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>

            <form class="modal-body"
                  [class.success]="hashedPan && highlighted"
                  [formGroup]="creditCardForm"
                  (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="pan">
                        {{ 'hash-pan.panControlLabel' | translate }}
                    </label>

                    <div class="input-group input-group-seamless-append">
                        <input id="pan"
                               class="form-control"
                               mask="0000 0000 0000 0000 000"
                               [validation]="false"
                               formControlName="pan"
                               placeholder="XXXX XXXX XXXX XXXX"
                               autocomplete="off"
                               (paste)="onPaste($event)"
                               (input)="onInput($event)"/>

                        <div class="input-group-append">
                            <button class="show-password-img-button" type="button">
                                <img src="assets/layout/images/{{cardBrand}}-logo.svg"
                                     width="30px"
                                     *ngIf="cardBrand && brands.includes(cardBrand)"/>
                            </button>
                        </div>
                    </div>

                    <span class="validation-error"
                          *ngIf="!creditCardForm.controls['pan'].valid && creditCardForm.controls['pan'].value">
                            {{ 'hash-pan.invalidPan' | translate }}
                    </span>
                </div>

                <button type="submit"
                        [class.mb-big]="!hashedPan"
                        class="btn button-red"
                        data-toggle="modal"
                        [disabled]="isDisabledSubmitBtn()">
                    {{ 'hash-pan.submitBtn' | translate }}
                </button>

                <div *ngIf="hashedPan" class="form-group hash-input">
                    <label for="hash">
                        {{ 'hash-pan.hashInputLabel' | translate }}
                    </label>

                    <div class="input-group input-group-seamless-append">
                        <input id="hash"
                               class="form-control"
                               readonly
                               [value]="hashedPan"/>

                        <div class="input-group-append">
                            <button class="show-password-img-button" type="button" (click)="copyHash()">
                                <img src="assets/layout/images/clipboard.svg"/>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="notification"
                     [class.success]="hashedPan && highlighted">
                    <span *ngIf="hashedPan && highlighted;else qwe">{{ 'hash-pan.notification.success' | translate }}</span>
                    <ng-template #qwe>
                        <span>{{ 'hash-pan.notification.plain' | translate }}</span>
                    </ng-template>
                </div>
            </form>
        </div>
    </div>
</div>
