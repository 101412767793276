import { Component, EventEmitter, Output } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
declare var $: any;

@Component({
    selector: 'app-events-content',
    templateUrl: './events-content.component.html',
    styleUrls: ['./events-content.component.scss'],
})
export class EventsContentComponent {
    @Output()
    public scrollTo = new EventEmitter();

    public events: [];

    constructor(private readonly configService: ConfigService) {
        this.events = this.configService.getSettings('events');
    }

    goToFAQ() {
        $('#eventsModal').modal('hide');
        setTimeout(() => {
            $('#questionAnswerModal').modal('show');
            this.scrollTo.emit('secondQuestionHref');
        }, 500);
    }
}
