import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
    @Input()
    id: string;
    @Input()
    public messageDialogTitle: string;
    @Input()
    public infoMessage: string;
    @Output()
    public onOk = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
}
