<div class="modal fade"
     id="eventsModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="eventsModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-slideout right-slider modal-lg" role="document" id="modalForEvents">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>

            <div class="modal-body px-md-5">
                <div id="allEvents">
                    <h3>{{ 'events.title' | translate }}</h3>
                </div>

                <app-event *ngFor="let event of events"
                           [event]="event"></app-event>

                <div id="thirdEvent" class="mt-5">
                    <h5>{{ 'events.eventTitle3' | translate }}</h5>
                </div>

                <div class="mt-3">
                    {{ 'events.event3' | translate }}
                    <a href="javascript:void(0)"
                       data-toggle="modal"
                       data-dismiss="modal"
                       (click)="goToFAQ()">{{ 'events.event3.link' | translate }}.</a>
                </div>
            </div>
        </div>
    </div>
</div>
