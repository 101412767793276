import { Action } from '@ngrx/store';
import { SessionTokenHolder } from '../../security/domain/session-token-holder';

export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';

export class SetSessionTokenAction implements Action {
    readonly type = SET_SESSION_TOKEN;

    constructor(public payload: SessionTokenHolder) {}
}
