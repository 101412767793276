import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

@Component({
    selector: 'app-question-answer',
    templateUrl: './question-answer.component.html',
    styleUrls: ['./question-answer.component.scss'],
})
export class QuestionAnswerComponent implements OnInit {
    public faqLink: string;
    public faqId: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
    ) {}

    public onClickFaqTab(tab) {
        this.faqLink = tab;
        const [, theme] = tab.split('.');
        this.faqId = theme;
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            const faq = params['faq'];

            if (faq) {
                const [faqTheme] = faq.split('-');
                $('#eventsModal').modal('hide');
                $('#passesDetailsModal').modal('hide');
                $('#questionAnswerModal').modal('show');

                this.onClickFaqTab(`question-answer.${faqTheme}`);

                setTimeout(() => {
                    $(`#${faq}`).collapse('show');
                    const el: HTMLElement = document.getElementById(faq);
                    if (el) {
                        el.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 500);
            }
        });

        this.onClickFaqTab('question-answer.bankCard');

        $('#questionAnswerModal').on('hide.bs.modal', () => {
            this.router.navigate([], {
                queryParams: { faq: null },
                queryParamsHandling: 'merge'
            }).then();
        });
    }
}
