import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';

import { MissedTranslateParamsException } from '../localization/exceptions';
import { IterableObject } from '../localization/domain';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root',
})
export class IvNgHelpersService {
    constructor(private readonly messageService: MessageService) {}

    /**
     * Display success primeng message with specific title and description
     * @param title - the title of success message
     * @param description - message text
     */
    public showSuccessMessage(title: string, description: string = ''): void {
        this.showMessage('success', title, description);
    }

    /**
     * Display error primeng message with specific title and description
     * @param title - the title of success message
     * @param description - message text
     */
    public showErrorMessage(title: string, description: string = ''): void {
        this.showMessage('error', title, description);
    }

    /**
     * A string formatting method that replaces keys of a {something} format in a string with values from the target object
     * @param targetString: string - target string
     * @param params: object - object where we need find values for string
     * @example
     * ```
     * this.ivNgHelpersService.getFormattedString('Hello, {user.name}!', { user: { name: 'Alex' } }) -> 'Hello, Alex!'
     * ```
     */
    public getFormattedString(targetString: string, params: IterableObject): string {
        const regExp: RegExp = /{([^}]+)}/g;
        const matches: string[] | null = targetString.match(regExp);
        if (!matches) {
            return targetString;
        } else if (!params) {
            throw new MissedTranslateParamsException(targetString);
        }
        let lastValue = null;
        matches.forEach((match, i) => {
            const paramName = match.slice(1, -1);
            let value = null;
            if (paramName.indexOf('plural: ') === 0) {
                // parsing plural form
                value = this.getPluralForm(lastValue, paramName.slice(7).split(',')).trim();
            } else {
                value = this.getNestedValue(paramName, params);
                lastValue = value;
            }
            targetString = targetString.replace(matches[i], value);
        });

        return targetString;
    }

    /**
     * Get plural form of word by count
     * @param count: number
     * @param pluralForms: [string, string, string]
     */
    public getPluralForm(count: number, pluralForms: string[]): string {
        let n = Math.abs(count);
        n %= 100;
        if (n >= 5 && n <= 20) {
            return pluralForms[2];
        }
        n %= 10;
        if (n === 1) {
            return pluralForms[0];
        }
        if (n >= 2 && n <= 4) {
            return pluralForms[1];
        }
        return pluralForms[2];
    }

    /**
     * The helper to simplify getting nested values in some object
     * @param field: string - the path to target field.
     * @param targetObject: object - the object, where we need get value by target field
     * @example
     * ```
     * this.ivNgHelpersService.getNestedValue('user.id', { user: { id: 1, name: 'Foo' } }) // returns 1;
     * ```
     */
    public getNestedValue(field: string, targetObject: { [key: string]: any }): any {
        const reducer = (accumulator, currentValue) => accumulator[currentValue];
        const keys = field.split('.');
        let value;

        if (keys.length) {
            try {
                value = keys.reduce(reducer, targetObject);
            } catch (e) {
                value = null;
            }
        } else {
            value = targetObject[field];
        }
        return value !== undefined ? value : null;
    }

    /**
     * Remove duplicate objects from JavaScript Array based on specific property/key
     * @param array: object[] - target array of objects to filter
     * @param key: string - target key of object
     * @example
     * ```
     * const array = [
     *  { user: 'Alex', birthday: '06-04-1994' },
     *  { user: 'Bill', birthday: '12-12-1999' },
     *  { user: 'Alex', birthday: '02-09-1991' },
     * ];
     * const filteredArr = this.ivNgHelpersService.filterObjectsArrayByKey(array);
     * // -> [  { user: 'Alex', birthday: '06-04-1994' }, { user: 'Bill', birthday: '12-12-1999' } ]
     * ```
     */
    public filterObjectsArrayByKey<T>(array: T[], key: string): T[] {
        const lookup = new Set();

        return array.filter((obj) => !lookup.has(obj[key]) && lookup.add(obj[key]));
    }

    /**
     * Helper to to simplify HttpParams creation that is used in GET requests as query params
     * @param params - object with params to be formatted to HttpParams entity
     */
    public createHttpParams(params: { [key: string]: any }): HttpParams {
        let httpParams = new HttpParams();
        Object.keys(params).forEach((key: string) => {
            httpParams = httpParams.append(key, String(params[key]));
        });
        return httpParams;
    }

    /**
     * Cross-browser download Blob file method
     * @param file: Blob - target file
     * @param fileName: string - the file name with extension. Example: 'foo.txt'
     */
    public saveFile(file: Blob, fileName: string): void {
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(file, fileName);
            return;
        }
        saveAs(file, fileName);
    }

    /**
     * Cross-browser 'copy to clipboard' method
     * @param value - target value to be copied to clipboard
     */
    public copyToClipboard(value: any): void {
        const selBox = document.createElement('textarea');
        const valueToCopy = value && typeof value === 'object' ? JSON.stringify(value) : String(value);

        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = valueToCopy;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    /**
     *  Check if the target is empty
     * @param obj: object - target object
     */
    public objectIsEmpty(obj: any): boolean {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Converting double type to integer for currencies
     * @param value - target integer number
     */
    public conversionDoubleToInt(value: number): number {
        return parseInt(String(value * 100), 10);
    }

    /**
     * Converting integer type to double for currencies
     * @param value - target double number
     */
    public conversionIntToDouble(value: number): string {
        return (+(value * 10).toFixed(1) / 10).toFixed(2);
    }

    /**
     * This method marks every control of the form with 'dirty' and 'touched' flags
     * that are used to display form control errors and red border highlighting.
     * @param form: FormGroup
     */
    public displayFormErrors(form: FormGroup): void {
        Object.keys(form.controls).forEach((key) => {
            const control = form.controls[key];

            control.markAsDirty();
            control.markAsTouched();
            control.updateValueAndValidity();
        });
    }

    private showMessage(severity: 'error' | 'success', summary: string, detail: string): void {
        this.messageService.clear();
        this.messageService.add({
            severity,
            summary,
            detail,
            sticky: true,
        });
    }
}
