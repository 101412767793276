import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../security/service/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LocalizationService } from '../../../localization/service/localization.service';
import emailMask from 'text-mask-addons/dist/emailMask';
import { SubSink } from '../../../localization/utils';
import { ConfigService } from '@ngx-config/core';
import { fromEvent, interval, Subscription } from 'rxjs';
import { RegistrationComponent } from '../../../registration/view/registration/registration.component';
import { Constants } from '../../../constants/Constants';
declare var $: any;

function confirmPasswordValidator(input: FormControl) {
    if (!input.value) {
        return null;
    }
    const exactMatch = input.parent.get('password').value === input.value;
    return exactMatch ? null : { mismatchedPassword: true };
}

function passwordSpecRulesValidator(input: FormControl) {
    if (!input.value) {
        return null;
    }
    const exactMatch = input.value.match(/^(?=.*[0-9])(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ])([a-zA-Zа-яёА-ЯЁ0-9]{8,})$/);
    return exactMatch ? null : { notValid: true };
}

@Component({
    selector: 'app-password-recovery',
    templateUrl: './password-recovery.component.html',
    styleUrls: ['./password-recovery.component.scss'],
})
export class PasswordRecoveryComponent implements OnInit, OnDestroy {
    @ViewChild('recoveryByEmailTab') recoveryByEmailTab: ElementRef;
    @ViewChild('codeFrom') codeFrom: ElementRef;
    @Input()
    public step;
    public formProcessing = false;
    public recoveryByPhone = true;
    public emailMask = emailMask;
    public recoveryForm: FormGroup;
    public currentMsisdn = '+79000000000';
    public currentEmail: string;
    public anonymousSessionId: string;
    public readonly subSink: SubSink = new SubSink();
    public profileNotFoundMessage: string;
    public otpSendingLockedMessage: string;
    public nonValidOtpCodeMessage: string;
    public nonValidOtpCodeEmail: string;
    public moreOtpRequest: string;
    public otpExpiredMessage: string;
    public otpAttemptsOverMessage: string;
    public anonymousSessionExpired: string;
    public otpLockedMessage: string;
    public step2Content1: string;
    public step2Content2: string;
    public badRequest: string;
    public passwordShown = false;
    public confirmPasswordShown = false;
    @Input()
    public capchaChecked: boolean;
    public reCaptchaSitekey: string;
    public capchaLang: string;
    public otpLinkTitle: string;
    public step2EmailContent1: string;
    public step2EmailContent2: string;
    public isEnabledEmail = true;
    public isEnabledRecaptcha: boolean;
    private sub: Subscription;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly authService: AuthService,
        private readonly router: Router,
        private messageService: MessageService,
        private readonly localizationService: LocalizationService,
        private readonly configService: ConfigService,
    ) {}

    ngOnInit(): void {
        const { auth } = this.configService.getSettings('enableEmail');
        this.isEnabledRecaptcha = this.configService.getSettings('enableRecaptcha');
        this.isEnabledEmail = auth;

        this.step = 1;
        this.recoveryForm = this.formBuilder.group({
            phoneLogin: ['', Validators.required],
            emailLogin: [
                '',
                [Validators.required, Validators.pattern(Constants.email)],
            ],
            otpCode: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
            password: ['', [Validators.required, passwordSpecRulesValidator]],
            confirmPassword: ['', [Validators.required, confirmPasswordValidator]],
            userAgreement: [''],
        });
        this.subSink.sink = this.localizationService.languageChanged.subscribe(() => {
            this.profileNotFoundMessage = this.localizationService.getMessage(
                'password-recovery.profileNotFoundMessage',
            );
            this.otpSendingLockedMessage = this.localizationService.getMessage('reg.otpSendingLockedMessage');
            this.step2Content1 = this.localizationService.getMessage('reg.step2Content1');
            this.step2Content2 = this.localizationService.getMessage('reg.step2Content2');
            this.nonValidOtpCodeMessage = this.localizationService.getMessage('reg.nonValidOtpCodeMessage');
            this.nonValidOtpCodeEmail = this.localizationService.getMessage('reg.nonValidOtpCodeEmail');
            this.moreOtpRequest = this.localizationService.getMessage('reg.moreOtpRequest');
            this.otpExpiredMessage = this.localizationService.getMessage('reg.otpExpired');
            this.anonymousSessionExpired = this.localizationService.getMessage('reg.anonymousSessionExpired');
            this.otpAttemptsOverMessage = this.localizationService.getMessage('reg.otpAttemptsOverMessage');
            this.otpLockedMessage = this.localizationService.getMessage('reg.otpLockedMessage');
            this.capchaLang = this.localizationService.getMessage('capchaLang');
            this.otpLinkTitle = this.localizationService.getMessage('reg.otpLinkTitle');
            this.step2EmailContent1 = this.localizationService.getMessage('reg.step2EmailContent1');
            this.step2EmailContent2 = this.localizationService.getMessage('reg.step2EmailContent2');
            this.badRequest = this.localizationService.getMessage('badRequest');
        });
        this.capchaChecked = false;
        this.reCaptchaSitekey = this.configService.getSettings('re_captcha_sitekey');

        fromEvent(document, 'show.bs.modal').subscribe(() => {
            this.recoveryForm.reset();
            if (this.sub) {
                this.messageService.clear();
                this.sub.unsubscribe();
            }
            this.messageService.clear();
        });
    }

    onKeyUp(): void {
        if (this.step === 2 && this.isValidByStep2()) {
           this.onRegFormStep2Submit();
        }
    }

    public onRecoveryFormStep1Submit(isNeedMoreOtp = false): void {
        this.messageService.clear();
        this.sub?.unsubscribe();
        this.formProcessing = true;
        let errorMessage;
        const value = this.recoveryForm.value;
        const recoveryObserver = {
            next: (response) => {
                if (isNeedMoreOtp) {
                    this.messageService.clear();
                    this.messageService.add({
                        severity: 'success',
                        summary: '',
                        detail: this.moreOtpRequest,
                        sticky: true,
                    });
                    if (this.codeFrom && this.codeFrom.nativeElement) {
                        this.codeFrom.nativeElement.click();
                    }
                }
                this.step = 2;
                this.capchaChecked = false;
                this.recoveryForm.controls['otpCode'].setValue('');
                this.anonymousSessionId = response.sessionId;
                this.formProcessing = false;
            },
            error: (errorData) => {
                this.messageService.clear();
                if (errorData.error.status === 'PROFILE_NOT_FOUND') {
                    errorMessage = this.profileNotFoundMessage;
                } else if (errorData.error.status === 'OTP_LOCKED') {
                    errorMessage = this.otpLockedMessage.replace(
                        'otpLockPeriod',
                        RegistrationComponent.getStringTimerValue(errorData.error.otpLockPeriod),
                    );
                    this.startOtpLockTimer(errorData.error.otpLockPeriod);
                } else if (errorData.status === 504) {
                    errorMessage = this.badRequest;
                } else {
                    errorMessage =
                        typeof errorData.error === 'string' ? errorData.message : errorData.error.description;
                }
                this.messageService.add({
                    severity: 'error',
                    summary: '',
                    detail: errorMessage,
                    sticky: true,
                });
                if (this.codeFrom && this.codeFrom.nativeElement) {
                    this.codeFrom.nativeElement.click();
                }
                this.formProcessing = false;
            },
        };
        if (this.recoveryByPhone) {
            this.currentMsisdn = value.phoneLogin;
            this.subSink.sink = this.authService.recoveryByMsisdnStep1(value.phoneLogin).subscribe(recoveryObserver);
        } else {
            this.currentEmail = value.emailLogin;
            this.subSink.sink = this.authService.recoveryByEmailStep1(value.emailLogin).subscribe(recoveryObserver);
        }
    }

    public isValidByStep1(): boolean {
        let result;

        if (this.isEnabledRecaptcha) {
          result = this.recoveryByPhone
            ? this.recoveryForm.controls['phoneLogin'].valid && this.capchaChecked
            : this.recoveryForm.controls['emailLogin'].valid && this.capchaChecked;
        } else {
          result = this.recoveryByPhone
            ? this.recoveryForm.controls['phoneLogin'].valid
            : this.recoveryForm.controls['emailLogin'].valid;
        }

        return result;
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

    public getMoreOtp(): void {
        if (this.formProcessing) { return; }
        this.recoveryForm.controls['otpCode'].setValue('');
        this.onRecoveryFormStep1Submit(true);
    }

    public onRegFormStep2Submit(): void {
        this.messageService.clear();
        this.sub?.unsubscribe();
        this.formProcessing = true;
        let errorMessage;
        const value = this.recoveryForm.value;
        const recoveryObserver = {
            next: () => {
                this.step = 3;
                this.formProcessing = false;
            },
            error: (errorData) => {
                this.messageService.clear();
                switch (errorData.error.status) {
                    case 'BAD_REQUEST':
                    case 'WRONG_OTP': {
                        errorMessage = this.recoveryByPhone ? this.nonValidOtpCodeMessage : this.nonValidOtpCodeEmail;
                        break;
                    }
                    case 'OTP_EXPIRED': {
                        errorMessage = this.otpExpiredMessage;
                        break;
                    }
                    case 'OTP_ATTEMPTS_OVER': {
                        errorMessage = this.recoveryByPhone
                            ? this.otpAttemptsOverMessage
                            : this.otpAttemptsOverMessage.replace('SMS', 'e-mail');
                        break;
                    }
                    case 'OTP_LOCKED': {
                        errorMessage = this.otpLockedMessage.replace(
                            'otpLockPeriod',
                            RegistrationComponent.getStringTimerValue(errorData.error.otpLockPeriod),
                        );
                        this.startOtpLockTimer(errorData.error.otpLockPeriod);
                        break;
                    }
                    case 'ANONYMOUS_SESSION_EXPIRED': {
                        errorMessage = this.anonymousSessionExpired;
                        break;
                    }
                    default: {
                        errorMessage =
                            typeof errorData.error === 'string' ? errorData.message : errorData.error.description;
                    }
                }
                if (errorData.status === 504) {
                    errorMessage = this.badRequest;
                }
                this.messageService.add({
                    severity: 'error',
                    summary: '',
                    detail: errorMessage,
                    sticky: true,
                });
                this.formProcessing = false;
            },
        };
        if (this.recoveryByPhone) {
            this.currentMsisdn = value.phoneLogin;
            this.subSink.sink = this.authService
                .recoveryByMsisdnStep2(value.phoneLogin, value.otpCode, this.anonymousSessionId)
                .subscribe(recoveryObserver);
        } else {
            this.currentEmail = value.emailLogin;
            this.subSink.sink = this.authService
                .recoveryByEmailStep2(value.emailLogin, value.otpCode, this.anonymousSessionId)
                .subscribe(recoveryObserver);
        }
    }

    public backToPreviousStep(): void {
        this.step = --this.step;
        if (!this.recoveryByPhone) {
            setTimeout(() => this.recoveryByEmailTab.nativeElement.click(), 0);
        }
        this.messageService.clear();
        this.sub.unsubscribe();
    }

    public isValidByStep2(): boolean {
        return this.recoveryForm.controls['otpCode'].valid;
    }

    isValidByStep3() {
        return (
            this.recoveryForm.controls['password'].valid &&
            this.recoveryForm.controls['confirmPassword'].valid &&
            this.recoveryForm.controls['password'].value === this.recoveryForm.controls['confirmPassword'].value
        );
    }

    public onRecoveryFormStep3Submit(): void {
        this.formProcessing = true;
        let errorMessage;
        const value = this.recoveryForm.value;
        const recoveryObserver = {
            next: () => {
                $('#authRegModal').modal('hide');
                this.router.navigate(['/ride-history']).then(() => { this.formProcessing = false; });
            },
            error: (errorData) => {
                // if (errorData.error.status === 'WITHOUT_CONSENT') {
                //     sessionStorage.setItem('sessionId', errorData.error.sessionId);
                //     $('#authRegModal').modal('hide');
                //     $('#userAgreementModal').modal('show');
                // }
                this.messageService.clear();
                switch (errorData.error.status) {
                    case 'ANONYMOUS_SESSION_EXPIRED': {
                        errorMessage = this.anonymousSessionExpired;
                        break;
                    }
                    default: {
                        errorMessage =
                            typeof errorData.error === 'string' ? errorData.message : errorData.error.description;
                    }
                }
                if (errorData.status === 504) {
                    errorMessage = this.badRequest;
                }
                this.messageService.add({
                    severity: 'error',
                    summary: '',
                    detail: errorMessage,
                    sticky: true,
                });
                this.formProcessing = false;
            },
        };
        if (this.recoveryByPhone) {
            this.currentMsisdn = value.phoneLogin;
            this.subSink.sink = this.authService
                .recoveryByMsisdnStep3(value.phoneLogin, value.password, this.anonymousSessionId)
                .subscribe(recoveryObserver);
        } else {
            this.currentEmail = value.emailLogin;
            this.subSink.sink = this.authService
                .recoveryByEmailStep3(value.emailLogin, value.password, this.anonymousSessionId)
                .subscribe(recoveryObserver);
        }
    }

    public showPassword(field): void {
        this[field] = !this[field];
    }

    capchaResponse($event: any) {
        this.capchaChecked = true;
    }

    checkPasswordsMatch() {
        this.recoveryForm.controls['confirmPassword'].updateValueAndValidity();
    }

    private startOtpLockTimer(otpLockPeriod: number) {
        $('#get-more-otp-tab-link').addClass('disabled');
        let currentTimerValue = otpLockPeriod;
        this.sub = interval(1000).subscribe((val) => {
            currentTimerValue = currentTimerValue - 1000;
            if (currentTimerValue < 0) {
                this.messageService.clear();
                $('#get-more-otp-tab-link').removeClass('disabled');
                this.sub.unsubscribe();
                return;
            }
            const otpLockedMessage = this.otpLockedMessage.replace(
                'otpLockPeriod',
                RegistrationComponent.getStringTimerValue(currentTimerValue),
            );
            this.messageService.clear();
            this.messageService.add({
                severity: 'error',
                summary: '',
                detail: otpLockedMessage,
                sticky: true,
            });
            $('.ui-messages-close').click(() => {
                this.messageService.clear();
                this.sub.unsubscribe();
                return;
            });
        });
    }
}
