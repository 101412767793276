import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../security/service/auth.service';
import { Router } from '@angular/router';
import { ConfigService } from '@ngx-config/core';

declare var $: any;

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {
  @Output()
  public delete = new EventEmitter();
  public isConfirmState = true;
  public personalDataLink: string;

  constructor(
      private readonly authService: AuthService,
      private readonly router: Router,
      private readonly configService: ConfigService
  ) {}

  onAgree(): void {
    this.authService.acceptPrivatePolicy().subscribe(
        () => {
          sessionStorage.removeItem('sessionId');
          this.router.navigate(['/ride-history']);
        },
        (err) => console.log(err)
    );
    $('#userAgreementModal').modal('hide');
  }

  ngOnInit(): void {
    this.personalDataLink = this.configService.getSettings('registrationPersonalDataLink');
    $('#userAgreementModal').on('hide.bs.modal', () => { this.isConfirmState = true; });
  }
}
