<div class="modal fade" id="questionAnswerModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-slideout right-slider modal-lg" role="document" id="modalForQuestionAnwer">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>

            <div class="modal-body px-lg-5 pb-lg-5">
                <div>
                    <h3>{{ 'question-answer.title' | translate }}</h3>
                </div>

                <div class="tabs-wrapper">
                    <button *ngFor="let tab of ('question-answer.tabs' | translate); let i = index"
                            type="button"
                            class="tab"
                            [class.active]="faqLink === tab['link']"
                            (click)="onClickFaqTab(tab['link'])">
                        {{ tab['title'] }}
                    </button>
                </div>

                <ng-container *ngFor="let faq of (faqLink | translate); let i = index">
                    <div class="pt-4">
                        <a data-toggle="collapse" [href]="'#' + faqId + '-' + (i + 1)">
                            {{ faq['question'] }}
                        </a>
                    </div>

                    <div [id]="faqId + '-' + (i + 1)"
                         class="collapse pt-4"
                         innerHTML="{{ faq['answer'] }}"></div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
