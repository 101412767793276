<div
    class="modal fade"
    id="confirmDeleteUserDialog"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCenterTitle"
    data-backdrop="static"
    aria-hidden="true"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="d-flex justify-content-center confirm-dialog-title">
                    {{ 'app.confirmDeleteUserTitle' | translate }}
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="flex-column pt-md-3 px-4 centered-content">
                        <div>{{ 'app.confirmDeleteUserString1' | translate }}</div>
                    </div>
                </div>
                <div class="mt-3 p-1 p-md-4 d-flex justify-content-between">
                    <button
                        type="button"
                        class="btn button-white"
                        data-toggle="modal"
                        data-dismiss="modal"
                        (click)="emitCancel()"
                    >
                        {{ 'noButtonLabel' | translate }}
                    </button>
                    <button
                        type="button"
                        class="btn button-red"
                        data-toggle="modal"
                        data-dismiss="modal"
                        tabindex="4"
                        (click)="emitOk()"
                    >
                        {{ 'yesButtonLabel' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
