import { Injectable, Injector } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
    NavigationExtras,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

declare var $: any;

@Injectable({
    providedIn: 'root',
})
export class HistoryGuard implements CanActivate {
    constructor(private auth: AuthService, private injector: Injector) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const navigationExtras: NavigationExtras = {
            queryParams: { returnURL: state.url },
        };
        const router = this.injector.get(Router);

        if (this.auth.isAuthenticated()) {
            return true;
        } else {
            if (state.url === '/hash-pan') {
                sessionStorage.setItem('routeAfterLogin', state.url);
                $('.modal').modal('hide');
                setTimeout(() => $('#authRegModal').modal('show'), 0);
            }
            router.navigate(['/'], navigationExtras);
            return false;
        }
    }
}
