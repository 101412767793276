import { Component, Input } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent {
  @Input()
  public event: any;
  @Input()
  public isMainPage = false;

  constructor() {}

  scroll(id: string): void {
    const el: HTMLElement = document.getElementById(id);

    $('#eventsModal').on('shown.bs.modal', () => {
      setTimeout(() => {
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);

      $('#eventsModal').off('shown.bs.modal');
    });
  }

}
