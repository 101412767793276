import { SessionTokenState } from '../states/app.states';
import * as sessionTokenAction from '../actions/actions';

export const initialState: SessionTokenState = { sessionTokenHolder: null };

export function sessionTokenReducer(
    state = initialState,
    action: sessionTokenAction.SetSessionTokenAction,
): SessionTokenState {
    switch (action.type) {
        case sessionTokenAction.SET_SESSION_TOKEN: {
            return {
                ...state,
                sessionTokenHolder: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}
