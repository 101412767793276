import {
    HttpErrorResponse,
    HttpEvent, HttpEventType,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { identity, Observable, throwError, timer } from 'rxjs';

import { AuthService } from '../../security/service/auth.service';
import { tap, takeUntil, finalize } from 'rxjs/internal/operators';
import { ConfigService } from '@ngx-config/core';

@Injectable()
export class IVInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private readonly configService: ConfigService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.endsWith('.json')) {
            return next.handle(req);
        }
        const { baseUrl, version } = this.configService.getSettings('openApi');
        let xhr = req.clone({
            url: `${baseUrl}${version}${req.url}`,
            headers: req.headers
                .set('X-Requested-With', 'XMLHttpRequest')
                .set('Content-Type', 'application/json; charset=UTF-8'),
        });

        if (
            this.injector.get(AuthService).isAuthenticated() &&
            !req.url.includes('register') &&
            !req.url.includes('restore')
        ) {
            xhr = xhr.clone({
                url: `${baseUrl}${version}${req.url}`,
                headers: xhr.headers.set(
                    'SESSION_ID',
                    `${this.injector.get(AuthService).getSessionTokenHolder.sessionToken}`,
                ),
            });
        }

        const { timeoutInSeconds: timeout, matchUrl } = this.configService.getSettings('cancelRequest');
        const isNeedCancelRequest = !!matchUrl.find(str => req.url.includes(str));

        let lastResponse: HttpEvent<any>;
        let lastError: HttpErrorResponse;

        const responseObserver = {
            next: (event) => {
                lastResponse = event;
                if (event instanceof HttpResponse) {
                    // do stuff with response if you want
                }
            },
            error: (err) => {
                lastError = err;
                if (err instanceof HttpErrorResponse) {
                    if (!req.url.includes('innersession/create') && err.status === 401) {
                        const errorStatus = err.error &&
                        (err.error.status === 'ANONYMOUS_SESSION_EXPIRED' || err.error.status === 'WITHOUT_CONSENT') ? -1 : 0;
                        this.injector.get(AuthService).dataStream.next(errorStatus);
                    }
                }
                const error = (err.error ? err.error.message : err.message) || err.statusText;
                return throwError(error);
            },
        };

        return next.handle(xhr).pipe(
            tap(responseObserver),
            isNeedCancelRequest ? takeUntil(timer(timeout * 1000)) : identity,
            finalize(() => {
                if (lastResponse.type === HttpEventType.Sent && !lastError) {
                    this.injector.get(AuthService).dataStream.next(2);
                }
            })
        );
    }
}
