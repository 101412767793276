<div class="modal fade" id="aboutService" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-center">
        <img src="{{ currentLogoBlackURL }}" alt="Brand logo" width="240" height="60"/>
      </div>

      <div class="modal-body">
        <div class="info">
          {{ 'ride-history.aboutService.info' | translate }}
        </div>

        <div class="py-5">
          <div *ngFor="let carrier of ('ride-history.aboutService.carrierNames' | translate)">
            - {{ carrier }}
          </div>
        </div>

        <div class="d-flex justify-content-center pt-3">
          <button type="button" class="btn button-white" data-dismiss="modal">
            {{ 'ride-history.aboutService.close' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
