<div class="modal fade supportModal"
     id="supportModal"
     tabindex="-1"
     role="dialog"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-slideout right-slider modal-lg" role="document" id="modalForSupport">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>

            <div class="modal-body d-flex flex-column px-lg-5">
                <div class="flex-grow-1">
                    <div>
                        <h3>{{ 'main-menu.support' | translate }}</h3>
                    </div>

                    <div class="pt-0 pt-md-4">
                        <p>
                            {{ 'client-support.contentPart1' | translate }}
                            <a href="javascript:void(0)"
                               data-toggle="modal"
                               data-dismiss="modal"
                               (click)="goToFAQ()">
                                {{ 'client-support.contentPart2' | translate }}
                            </a>
                            {{ 'client-support.contentPart4' | translate }}
                            <a [href]="supportHref">{{ 'client-support.contentPart5' | translate }}</a>
                        </p>
                    </div>
                </div>

                <div class="mt-3">
                    <div class="brands-wrapper mb-4">
                        <div>
                            <img src="{{ currentLogoBlackURL }}"
                                 alt="Brand logo"
                                 class="{{ brandLogoCSSClass }}"/>
                        </div>

                        <div>
<!--                            <img class="vtb-logo"-->
<!--                                 src="assets/layout/images/logo-vtb-black.svg"-->
<!--                                 alt="VTB logo"-->
<!--                                 style="height: 50px;"/>-->
                        </div>
                    </div>

<!--                    <div class="about-service">Сервис предоставляется при участии Банка ВТБ (ПАО)</div>-->

                    <div class="d-flex mb-4 mt-2">
                        <span class="copyright d-sm-none d-md-block" innerHTML="{{ 'main-view.copyright' | translate }}"></span>

                        <div appAuth [hideIfNotAuthenticated]="true" class="delete-link ml-5">
                            <a (click)="showConfirmToDeleteUserProfileDialog()">
                                {{ 'app.deleteUserProfileLinkLabel' | translate }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
