import { Pipe, PipeTransform } from '@angular/core';

import { LocalizationService } from '../service/localization.service';
import { IterableObject } from '../domain';

@Pipe({
    name: 'translate',
    pure: false,
})
export class TranslatePipe implements PipeTransform {
    constructor(private readonly localizationService: LocalizationService) {}

    transform(value: string, params?: IterableObject): string {
        return this.localizationService.getMessage(value, params);
    }
}
