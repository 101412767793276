import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { LanguageRegistry } from '../domain';
import { LocaleLoadingException, MissedLocaleKeyException } from '../exceptions';
import { IvNgHelpersService } from '../../helpers/iv-ng-helpers.service';
import { IterableObject } from '../domain';

@Injectable({
    providedIn: 'root',
})
export class LocalizationService {
    // tslint:disable-next-line:variable-name
    private _availableLanguages: string[] = [];

    private readonly language: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly languageRegistry: LanguageRegistry = {};

    public languageChanged: Observable<string> = this.language.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly cookieService: CookieService,
        private readonly ivNgHelpersService: IvNgHelpersService,
    ) {}

    /**
     * Get specific string by key
     * @param key - the key of message in localization json file
     * @param params - optional params with values for cases, when target string contains variables to replace with values
     * @param defaultValue - optional default value, used in case, when target value not found in json file
     */
    public getMessage(key: string, params?: IterableObject, defaultValue?: any): string {
        const currentLanguage: string = this.language.getValue();
        const languageMap: { [key: string]: string } = this.languageRegistry[currentLanguage];

        if (!currentLanguage) {
            return;
        }
        if (!languageMap[key] && defaultValue === undefined) {
            console.log(`Missed message with key ${key} in ${currentLanguage} map.`);
            throw new MissedLocaleKeyException(key, currentLanguage);
        } else if (!languageMap[key] && defaultValue !== undefined) {
            return defaultValue;
        }
        return params ? this.getFormattedMessage(languageMap[key], params) : languageMap[key];
    }

    /**
     * This function translates the English string into the current language by breaking it into separate words
     * and expressions and replacing them with similar ones in JSON file (if exists)
     * @param engString - target english string
     * @param keyPrefix - optional prefix that precedes the target words in the json localization file
     * (Example: trx.timeline prefix => trx.timeline.hello: 'Привет, таймлайн' in JSON file)
     * @returns string - localized string
     */
    public translateEngString(engString: string, keyPrefix?: string): string {
        const words = engString.replace(/[^.a-zA-Z]+/g, '|').split('|');
        let localizedString = engString;
        words.forEach((word) => {
            const localizedWord = this.getMessage(keyPrefix ? `${keyPrefix}.` + word : word, null, '');
            if (localizedWord) {
                localizedString = localizedString.replace(word, localizedWord);
            }
        });
        return localizedString;
    }

    /**
     * Switch application language
     * @param locale - A string name of new locale
     */
    public setLocale(locale: string): void {
        if (!Object.keys(this.languageRegistry).includes(locale)) {
            this.http.get<{ [key: string]: string }>(`./assets/lang/${locale}.json`).subscribe(
                (data) => {
                    this.languageRegistry[locale] = data;
                    this.language.next(locale);
                    this.cookieService.set('currentLang', locale, null, '/');
                },
                () => {
                    throw new LocaleLoadingException(locale);
                },
            );
        } else {
            this.language.next(locale);
        }
    }

    /**
     * Set list of all available languages
     * @param availableLocales - array of all available locales, situated in assets folder
     */
    public setAvailableLanguages(availableLocales: string[]): void {
        this._availableLanguages = availableLocales;
    }

    /**
     * Get array of all available languages
     */
    public get availableLanguages(): string[] {
        return this._availableLanguages;
    }

    /**
     * Get current application language
     */
    public get currentLanguage(): string {
        return this.language.getValue();
    }

    /**
     *  This method replaces all variables in message like: 'Hello, {name}' with actual values from params object
     * @param message - target message. Example: 'Hello, {name}'
     * @param params - iterable entity with replacing values. Example: { name: 'Alex' }.
     */
    private getFormattedMessage(message: string, params: IterableObject): string {
        return this.ivNgHelpersService.getFormattedString(message, params);
    }
}
