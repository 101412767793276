import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Card } from '../domain/card';
import { RegResponse } from '../../security/domain/reg-response';
import { catchError } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from '../../http/http-error-handler.service';
import { AuthService } from '../../security/service/auth.service';
import { Observable } from 'rxjs';
import { PassesListPart } from '../domain/passes-list-part';
import { PsFilter } from '../domain/ps-filter';
import { PsListRequest } from '../domain/ps-list-request';
import { PaymentsListPart } from '../domain/payments-list-part';
import { CreateIssueRequest } from '../domain/create-issue-request';
import { LocalizationService } from '../../localization/service/localization.service';

@Injectable({
    providedIn: 'root',
})
export class RideHistoryService {
    private readonly handleError: HandleError;

    constructor(
        private readonly http: HttpClient,
        private readonly authService: AuthService,
        private readonly httpErrorHandler: HttpErrorHandler,
        private readonly localizationService: LocalizationService,
    ) {
        this.handleError = httpErrorHandler.createHandleError('RideHistoryService');
    }

    public getCardList(): Observable<any> {
        return this.http.get<any>('/tp/cards', {}).pipe(catchError(this.handleError('getCardList', [])));
    }

    public startToLinkCard(): Observable<any> {
        const language = this.localizationService.currentLanguage.toUpperCase();
        return this.http
            .get<any>(`/tp/link/create?language =${language}`, {})
            .pipe(catchError(this.handleError('startToLinkCard', [])));
    }

    public getLinkCardStatus(id: string): Observable<any> {
        return this.http.post('/tp/link/status', { id }).pipe(catchError(this.handleError('getLinkCardStatus', [])));
    }

    public deleteCard(id: string): Observable<any> {
        return this.http.delete('/tp/link/delete/' + id).pipe(catchError(this.handleError('deleteCard', [])));
    }

    public makeCardFavorite(id: string): Observable<any> {
        return this.http
            .put('/tp/cards/make/favourite/' + id, {})
            .pipe(catchError(this.handleError('makeCardFavorite', [])));
    }

    public startToPayDebtOnCard(card: string): Observable<any> {
        const language = this.localizationService.currentLanguage.toUpperCase();
        return this.http
            .post<any>('/tp/repayment/start', { card, language })
            .pipe(catchError(this.handleError('startToPayDebtOnCard', [])));
    }

    public getPayDebtCardStatus(id: string): Observable<any> {
        return this.http
            .post('/tp/repayment/status', { transaction: id })
            .pipe(catchError(this.handleError('getPayDebtCardStatus', [])));
    }

    public getPassesListPart(passesListRequest: PsListRequest): Observable<PassesListPart> {
        return this.http
            .post<any | PassesListPart>('/tp/cards/passes', passesListRequest)
            .pipe(catchError(this.handleError('getPassesListPart', [])));
    }

    public getPaymentsListPart(paymentsListRequest: PsListRequest): Observable<PaymentsListPart> {
        return this.http
            .post<any | PassesListPart>('/tp/cards/payments', paymentsListRequest)
            .pipe(catchError(this.handleError('getPaymentsListPart', [])));
    }

    public createIssue(createIssueRequest: CreateIssueRequest): Observable<any> {
        return this.http
            .post<any>('/tp/issues/create', createIssueRequest)
            .pipe(catchError(this.handleError('createIssue', [])));
    }

    public getReceiptLink(receipt: string): Observable<any> {
        return this.http
            .post<any>('/tp/card/payment/receipt', { receipt })
            .pipe(catchError(this.handleError('receipt', [])));
    }

    public getLinkedPassByTravelId(payload): Observable<any> {
        return this.http
            .post<any>('/tp/card/pass/travel', payload)
            .pipe(catchError(this.handleError('getLinkedPassByTravelId', [])));
    }

    public downloadPassesListPart(passesListRequest: PsListRequest): Observable<any> {
        passesListRequest['responseFormat'] = 'EXCEL';
        passesListRequest['language'] = this.localizationService.currentLanguage.toUpperCase();
        return this.http
            .post<any>('/tp/cards/passes', passesListRequest, {
                observe: 'response',
                responseType: 'blob' as 'json',
            })
            .pipe(catchError(this.handleError('downloadPassesListPart', [])));
    }

    public downloadPaymentsListPart(paymentsListRequest: PsListRequest): Observable<any> {
        paymentsListRequest['responseFormat'] = 'EXCEL';
        paymentsListRequest['language'] = this.localizationService.currentLanguage.toUpperCase();
        return this.http
            .post<any>('/tp/cards/payments', paymentsListRequest, {
                observe: 'response',
                responseType: 'blob' as 'json',
            })
            .pipe(catchError(this.handleError('downloadPaymentsListPart', [])));
    }
}
