import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { LocalizationService } from '../../../localization/service/localization.service';
import { SubSink } from '../../../localization/utils';

@Component({
    selector: 'app-about-mug',
    templateUrl: './about-mug.component.html',
    styleUrls: ['./about-mug.component.scss'],
})
export class AboutMugComponent implements OnInit, OnDestroy {
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onBack = new EventEmitter<boolean>();
    downloadAppleUrl: string;
    downloadGoogleUrl: string;
    public readonly subSink: SubSink = new SubSink();

    constructor(private readonly localizationService: LocalizationService) {}

    ngOnInit(): void {
        this.subSink.sink = this.localizationService.languageChanged.subscribe(() => {
            this.downloadAppleUrl = this.localizationService.getMessage('downloadAppleUrl');
            this.downloadGoogleUrl = this.localizationService.getMessage('downloadGoogleUrl');
        });
    }

    back() {
        this.onBack.emit();
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }
}
