import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-confirm-delete-user-dialog',
    templateUrl: './confirm-delete-user-dialog.component.html',
    styleUrls: ['./confirm-delete-user-dialog.component.scss'],
})
export class ConfirmDeleteUserDialogComponent implements OnInit {
    @Output()
    public onOk = new EventEmitter();
    @Output()
    public onCancel = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    emitOk() {
        this.onOk.emit();
    }

    emitCancel() {
        this.onCancel.emit();
    }
}
