<div class="modal-body about-mug">
    <div class="d-flex justify-content-center about-mug-title">{{ 'about-mug.title' | translate }}</div>
    <div class="d-flex justify-content-center align-items-center">
        <div class="flex-column pt-md-3 px-4 centered-content">
            <div>{{ 'about-mug.string1' | translate }}</div>
            <div>{{ 'about-mug.string2' | translate }}</div>
            <div>{{ 'about-mug.string3' | translate }}</div>
            <div class="pt-1 pt-md-4">
                <img src="assets/layout/images/mug-screen-1.svg" width="250" height="311" />
            </div>
        </div>
    </div>
</div>
<div class="modal-footer login-reg">
    <div class="d-flex mx-auto p-3">
        <div class="px-4 d-flex align-items-center" style="height: 60px; border-right: 1px solid #b2b2b2;">
            <a target="_blank"
               href="https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%B9-%D1%83%D0%BC%D0%BD%D1%8B%D0%B9-%D0%B3%D0%BE%D1%80%D0%BE%D0%B4/id1436811607">
                <img src="{{ downloadAppleUrl }}" alt="AppStore" class="download-in" />
            </a>
        </div>
        <div class="px-4 d-flex align-items-center" style="height: 60px;">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=ru.bm.transport&hl=ru">
                <img src="{{ downloadGoogleUrl }}" alt="GooglePlay" class="download-in" />
            </a>
        </div>
    </div>
    <div class="d-flex mx-auto p-2">
        <button type="button" class="btn button-transparent" data-toggle="modal" (click)="back()">
            {{ 'backButtonLabel' | translate }}
        </button>
    </div>
</div>
