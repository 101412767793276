import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../../../localization/service/localization.service';
import { SubSink } from '../../../localization/utils';

@Component({
    selector: 'app-about-service',
    templateUrl: './about-service.component.html',
    styleUrls: ['./about-service.component.scss'],
})
export class AboutServiceComponent implements OnInit {
    protected readonly subSink: SubSink = new SubSink();
    public currentLogoBlackURL: string;

    constructor(private readonly localizationService: LocalizationService) {
        this.subSink.sink = this.localizationService.languageChanged.subscribe(() => {
            this.currentLogoBlackURL = this.localizationService.getMessage('app.currentLogoBlackURL');
        });
    }

    ngOnInit(): void {}
}
