<div *ngIf="isMainPage; else modalEvent"
     class="card-body">
    <h5 class="card-title">{{ event.mainEventSubTitle | translate }}</h5>
    <br>
    <h6 class="card-subtitle">{{ event.mainEventTitle | translate }}</h6>
    <a class="stretched-link"
       data-toggle="modal"
       href="#eventsModal"
       (click)="scroll(event.id)"></a>
</div>

<ng-template #modalEvent>
    <div [id]="event.id" class="mt-5">
        <h5>{{ event.modalTitleEvent | translate }}</h5>
    </div>

    <div class="mt-3"
         innerHTML="{{ event.modalEvent | translate }}">
    </div>
</ng-template>